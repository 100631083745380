import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const institutionRouter = {
  path: "/insti",
  component: Layout,
  redirect: "/insti/admin",
  meta: {
    icon: "insti-admin",
    title: $t("institution.institutionTitle"),
    rank: 2,
    authority: ["acquirer"]
  },
  children: [
    {
      path: "/insti/admin",
      name: "institutionTitle",
      component: () =>
        import("/@/views/institution-admin/InstitutionalAdmin/index.vue"),
      meta: {
        title: $t("institution.institutionTitle"),
        authority: ["acquirer"]
      }
    },
    {
      path: "/insti/detail",
      name: "institutionDetail",
      component: () =>
        import("/@/views/institution-admin/InstitutionalDetail/index.vue"),
      meta: {
        title: $t("institution.institutionDetail"),
        showLink: false,
        authority: ["acquirer"],
        activeMenu: "/insti/admin"
      }
    },
    {
      path: "/insti/add",
      name: "institutionAdd",
      component: () =>
        import("/@/views/institution-admin/InstitutionalAdd/index.vue"),
      meta: {
        title: $t("institution.institutionAdd"),
        showLink: false,
        authority: ["acquirer"],
        activeMenu: "/insti/admin"
        // keepAlive: true
      }
    },
    {
      path: "/insti/info",
      name: "institutionInfo",
      component: () =>
        import("/@/views/institution-admin/InstitutionalInfo/index.vue"),
      meta: {
        title: $t("组织信息"),
        showLink: false,
        authority: ["acquirer"],
        activeMenu: "/insti/admin"
      }
    }
  ]
};

export default institutionRouter;
