buttons:
  hsLoginOut: Logout
  hsUpdatePassword: Update Password
  hslogin: Login
  hsadd: New
  hssave: Save
  hssearch: Search
  hsdelete: Delete
  hsreload: Reload
  hscancel: Cancel
  hsconfirm: OK
  nextstep: Next
  reupload: Re-upload
  hsback: Back
menus:
  hshome: Dashboard
  hslogin: Login
  hsUpdatePassword: Update Password
  hserror: Error
  hsfourZeroFour: "404"
  hsfourZeroOne: "403"
  hsFive: "500"
  permission: Authority
  permissionPage: Page
  permissionButton: Button
  table: Form
  tablePage: Simple Form
  dataBoard: Dashboard
  accountMgr: Accounting
  reconMgr: Reconciliation
  errorMgr: Error Resolve
  accountData: Account Management
  serviceFeeMgr: Service Charge
  dealDetails: Transaction Details
  reconFile: Reconciliation File
  erroReview: Resolve Audit
  dataReport: Data Report
  businessReport: Business Report
  rankingList: Ranking
  transactionAnalysis: Transaction Analysis
  currencyAnalysis: Currency Analysis
  tagMgr: Tag
  terminalMgr: Terminal
  terminalMgrAdd: New
  terminalMgrEdit: Edit
  terminalMgrDetails: Terminal Details
  equipRegister: Device Registration

  reconResultDetails: Reconciliation Details
  reconData: Reconciliation Data
  reconResult: Reconciliation Result
  reconDetail: Reconciliation Detail
  reconDetails: Reconciliation Details
  errorHandleDetail: Error Resolve Details

  dataPreflight: Review
  auditAdmin: Review
  merchantTitle: Merchant
  merchantInfor: Merchant Info
  merchantRecycle: PMR Recycle
  auditDetails: Audition Details
  abnormal: Abnormal Handle
  abnormalDetail: Handle
status:
  hsLoad: Loading...
input:
  please_enter: Please input
  please_select: Please select
  support_merchant_name_or_mid_fuzzy_query: Support merchant name or PMR
  please_select_date_day: Please select day
  please_select_date_week: Please select week
  please_select_date_month: Please select month
page:
  当前角色: Current Role
  hsSwitchRoles: Switch Role
账户中心: User Center
accountCnter:
  pageTitle: User Center
  userTitle: User
  roleTitle: Role
  add: New
  edit: Edit
acountMgr:
  开始对账: Start Reconciliation
  对账时间: Time
  对账状态: Status
  账期: Billing Period
  账单ID: Bill ID
  账单周期(季度/月): Billing Cycle(Quarter/Month)
  账单开始时间: Billing Start Time
  账单结束时间: Bill End Time
  计费天数: Billing Days
  计费模式: Billing Model
  消费笔数: Number of Consumption
  消费金额($): Amount($)
  退货笔数: Number of Refund
  退货金额($): Refund amount($)
  退货净额($): Net Transaction($)
  服务费($): Service Fee($)
  查看明细: View Details
  交易明细: Transaction Details
  通道文件名称: Channel File Name
  对账日期: Reconciliation Date
  文件大小: File Size
  最后更新时间: Last Update Time
  结束对账: Close Reconciliation
  对账文件导入: Reconciliation File Import
  查看数据: View
  重新导入: Re-upload
  导入文件: Import File
  对账进度: Reconciliation Progress
  通道名称: Channel Name
  当前执行批次: Current Batch
  对账开始时间: Start Time
  对账结束时间: End Time
  差错处理状态: Resolve Status
  查看结果: View
  重新对账: Reconciliation
  差错类型: Error Type
  笔数: Number
  金额: Amount
  对账金额: Reconciliation Amount
  网关交易流水: System Transactions
  通道流水: Channel Transactions
  总金额: Total Amount
  对平金额: Balanced
  未对平金额: Unbalanced
  未对账金额: Unreconciled
  未对账笔数: Unreconciled
  总笔数: Total Number
  对平笔数: Balanced
  未对平笔数: Unbalanced
  对账明细: Reconciliation Details
  通道ID: Channel ID
  交易日期: Transaction Date
  交易时间: Transaction Time
  交易流水号/渠道订单号: Out Transaction/Channel Order Number
  交易订单号/参考号: Transaction Order/Reference Number
  商户号: PMR
  终端号: Terminal ID
  商户名称: Merchant Name
  交易类型: Transaction Type
  交易名称: Transaction Name
  交易属性: Transaction Properties
  付款账户/卡号: Payment Account/Card Number
  收单机构: Institution
  交易币种: Currency
  交易金额: Amount
  结算日期: Settlement Date
  原交易订单号: Original Transaction Order Number
  对账结果: Reconciliation Result
  对账结果描述: Description
  对账笔数: Number
  差错处理: Resolve of Error
  结算日: Settlement Date
  对账日: Reconciliation Date
  对账批次号: Reconciliation Batch Number
  对账环节: Reconciliation
  对账日志生成时间: Reconciliation log generation time
  上传文件: Upload File
  选择文件: Select File
  提交文件: Submit File
  提交所有信息: Submit all Information
  支持扩展名: Extension Supported
  文件提交中: File Submission
  文件提交成功: File Submitted Successfully
  文件提交失败: File submission failed
  信息提交中: Info Submitting
  信息提交失败: Info Submitting Successfully
  信息提交完成: Info Submitting Complete
  通道类型: Channel Type
  下载支付数据对账文件: Download Reconciliation File
  网关交易系统对账数据: System Reconciliation Data
  支付通道对账数据: Channel Reconciliation Data

systemSetting:
  pageTitle: System Setting

erroReview:
  applicationNo: Requisition Number
  subbatchNum: Reconciliation Batch
  errorDesc: Error Description
  handleStatus: Process Status
  Details: Details
  lookOver: View
  reconciliationDate: Reconciliation Date
  channelName: Channel Name
  executionBatch: Process Batch
  submissionTimesOfErrorApplication: Number of Submission
  reconciliationStatus: Reconciliation Status
  errorType: Type
  channelNum: Channel ID
  switchToMID: PMR
  transferInMerchantName: Merchant Name
  switchOutMID: Channel PMR
  transferOutMerchantName: Channel Merchant Name
  switchOutTID: Channel TID
  switchInTID: TID
  merchantOrderNo: Merchant Order Number
  transactionSerialNumber: Voucher Number
  returnSerialNumber: Refund Voucher Number
  transactionCurrency: Currency
  transactionAmount: Amount
  transactionType: Transaction Type
  tradingTime: Transacton Time
  transactionDate: Transaction Date
  settlementDate: Settlement Date
  sumSeaAppOrderNo: Requisition Number
  searchAndSerialNumber: Requisition Order Number
  toHandle: To be Processed
  errorReconciliation: Error adjustment
  businessUnilateralSystem: Business System Unilateral
  tradingUnilateralSystem: Payment System Unilateral
  submitForReview: Submit
  all: All
  listData: List Data
  reject: Reject
  pass: Pass
  flowInfoOfOriChanel: Original Channel Transaction Information
  errorReason: Reason
  errorHandlingProcess: Resolve Process
  writeOffReason: Reason for write-off
  writeOffVoucher: Write-off Certificate
  wheToParInSubRec: Participate in the follow-up reconciliation
  view: Preview
  reviewProcess: Audit Process
  auditStatus: Audit Status
  errorReviewStatus: Audit Status
  errorReconciliationStatus: Adjustment Status
  toReview: "Pending Audit"
  underReview: "Auditing"
  reviewed: "Audited"
  notReview: "Not Audited"
  notHandle: "Unprocessed"
  handlened: "Processed"
  unadjustedAccount: "Not Adjusted"
  adjustedAccount: "Adjusted"
  reviewReject: "Rejected"
  detail: "Detail"

merchant:
  merchantOpera: Operation Status
  merchantAdd: New Merchant
  merchantAddChild: New Sub-Merchant
  merchantDetails: Details
  merchantEdit: Edit Merchant

transactionFlow:
  pageTitle: Transaction Records
  queryPlaceholder: Merchant Order Number/SN

log:
  pageTitle: Log
  operationLog: Operation Log
  transactionLog: Transaction Log

common:
  创建时间: Creation Time
  机构ID: Institution ID
  机构名称: Institution Name
  操作: Operation
  操作人: Operator
  上传文件: Upload File
  处理: Process
  展开: Expand
  收拢: Collapse
  查看: View
  搜索: Search
  审核: Review
file:
  fileTitle: File
  fileExport: File Export
  fileImport: File Import
# 数据看板
数据看板: Dashboard
今日实时概况: Live Overview
(按自然日统计): (Statistics by live overview)
更新: Update
选择币种：: Currency：
选择币种: Select Currency
商户数量: Merchants
终端数量: Terminals
渠道数量: Channels
活跃终端数: Active Terminals
活跃商户数: Active Merchants
切换成英文: English
切换成中文: Chinese(Simplify)
设为默认币种: Set as Default Currency
当前默认: Current Default
消费金额排行（TOP10）: Amount Ranking（TOP10）
消费笔数排行（TOP10）: Ranking of Consumption（TOP10）
点击折叠: Click to Collapse
回到顶部: Back to Top
机构名称...: Institution Name...
MerchEnabled: Enabled
MerchDisabled: Disabled
MerchLogout: Unregistered
TerEnabled: Enabled
TerDisabled: Disabled
TerLogout: Unregistered
机构商户数量: Number of Merchants
交易笔数占比: The Proportion of Transactions
机构终端数量: Number of Terminals
机构终端占比: The Proportion of Terminals
系统现有渠道总数: Total Number of Channels
系统现有商户总数: Total Number of Merchants
所有终端数量: Total Number of Terminals
在统计时间内有交易产生（消费+退货）的商户数量: The number of merchants with transactions (consumption + return) during the statistical period
在统计时间内有交易产生（消费+退货）的终端数量: The number of terminals with transactions (consumption + return) during the statistical period
消费笔数：: Transaction：{value}
消费金额：: Amount：{value}
机构消费金额占比: Proportion of Institutional Consumption Amount
机构消费笔数占比: Proportion of institutional Consumption
渠道消费笔数: Channel Consumption
渠道消费笔数占比: Consumption
渠道消费金额: Channel Consumption Amount
渠道消费金额占比: Amount
在营：: Operating：{total}
启用：: Enable：{total}
0级机构: Institution
#数据报表
日报: Daily
周报: Weekly
月报: Monthly
展开: Expand
收拢: Collapse
查询: Search
重置: Reset
导出PDF: Export PDF
经营概况: Business Overview
现有商户: Existing Merchant
在营商户: Merchant In Operation
在营率: Operating Rate
新增商户: New Merchant
注销商户: Unregistered Merchant
行业: Industry
经营商户数: Number Of Operating Merchants
在营率（按商户）: Operating Rate
消费笔数: Transaction
排名变化: Rank change
前10排名: Top 10
营业净额较上一周: Net Turnover Compared To Last Week
类别: Type
占比: Rate
消费单均价: Average Price
消费金额行业占比: Industry Share Of Consumption Amount
较上一日: Compared To The Previous Day
消费笔数行业排行: Industry Ranking By Number Of Consumption
较上一周同天: Compared To Same Day As Last Week
KPI概况: KPI Overview
报表数据: Report Data
消费笔数行业占比: Industry Share Of Consumption
消费金额行业排行: Consumption Amount Industry Ranking
消费金额趋势: Amount Trend
时间: Time
消费笔数趋势: Transaction Trend
上一周同天: Same Day As Last Week
当日: This Day
消费金额: Amount
当周: This Week
当月: This Month
上一周: Last Week
上一月: Last Month
较上一周: Compared To Last Week
较上一月: Compared To Last Month
去年同月: Same Month Last Year
较去年同月: Compared To Same Month Last Year
消费单均价趋势: Average Price Trend
消费单均价行业占比: Industry Share Of Average Price
更多: More
kpi: kpi
当日排名: Ranking Of This Day
机构名称（0级）: Institution Name
导出: Export
上一日排名: Previous Day's Ranking
消费汇总: Consumption Summary
消费明细: Consumption Details
交易金额: Amount
交易笔数: Transaction
交易日期: Transaction Date
币种: Currency
收入汇总: Income Summary
收入明细: Income Details
消费金额（￥）: Amount
金额占比: Amount Percentage
笔数占比: Transaction Percentage
日期: Date
消费金额（: Amount（{currency}）
消费笔数（: Transaction（{currency}）
消费单均价（: Average Price（{currency}）
退货金额（: Refund Amount（{currency}）
退货笔数: Refund
暂无数据: No Data
消费金额排行: Amount Ranking
消费笔数排行: Consumption Ranking
后10排名: Bottom 10
消费金额（CNY）: Amount
营业净额较上一月: Net Turnover Compared To The Previous Month
请先输入查询条件: Please enter the query conditions
当日消费金额（: Amount Spent On This Day（{currency}）
当月消费金额（: Amount Spent On This Month（{currency}）
上一日消费金额（: Amount Spent On Previous Day（{currency}）
上一月消费金额（: Amount Spent On Last Month（{currency}）
当日消费笔数: Transaction On This Day
上一日消费笔数: Transaction On Previous Day
当月消费笔数: Transaction On This Month
上一月消费笔数: Transaction On Last Month
当月消费单均价: Average Price On This Month
上一月消费单均价: Average Price On Last Month
分析报告: Analysis Report
请填写所有查询条件: Please enter all the query conditions
请选择日期和币种: Please select a date and currency
上一日: Previous Day
周一: Monday
周二: Tuesday
周三: Wednesday
周四: Thursday
周五: Friday
周六: Saturday
周日: Sunday
选择日期: Select Date
选择时间: Select Time
选择统计标签: Select The Statistics Tag
日排行: Daily Ranking
选择统计角色: Choose Statistics Role
周排行: Weekly Ranking
月排行: Monthly Ranking
较上一日排名变化: Rank Changing From The Previous Day
较上一周同天排名变化: Rank Changing From The Same Day Of Last Week
消费笔数排行榜: Consumption Ranking
消费金额排行榜: Amount Ranking
币种分析: Currency Analysis
交易分析: Transaction Analysis
请先选择机构: Please Select Institution
# 标签管理
新增标签组: New Tag Group
新增标签: New Tag
添加标签: New Tag
标签名称: Tag Name
标签组名称: Name of Tag Group
搜索标签/标签组: Tag/Tag Group
删除标签成功: Delete Tag Successfully
删除标签组成功: Delete Tag Group Successfully
标签组添加成功: Tag Group Added Successfully
标签添加成功: Tag Added Successfully
标签名称重复: Duplicate Tag Name
标签组名称重复: Duplicate Tag Group Name
当前已选中: Currently Selected
个标签组: Tag Groups
是否确认删除？: Confirm to delete?
标签: Tag
# 终端管理
禁用: Disable
新增: New
启用: Enable
禁用成功: Successfully Disabled
删除成功: Successfully Deleted
启用成功: Successfully Enabled
是否禁用该终端: Whether to disable the terminal?
是否启用该终端: Whether to enable the terminal?
删除后，终端TID关联的S/N资源将被释放是否继续？: After deletion, the S/N associated with the terminal ID will be released. Do you want to continue?
重置秘钥: Reset Key
当前终端管理模式：终端标识 + 商户标识: "Current terminal management mode: PMR + TID"
当前终端管理模式：终端标识: "Current terminal management mode: TID"
商户名称: Merchant Name
设备S/N信息: Device S/N
无需对S/N校验，非必填: Optional S/N not verified
厂商: Vendor
机型: Model
终端支付参数回填: Fill terminal payment parameter
当前商户共计开通: The merchant has used {channels} {channelNum} channels, of which only "{channelsR}" needs to fill the terminal payment parameters, please complete the filling as required
转出终端标识参数: Channel TID
转出商户标识参数: Channel PMR
终端详情: Terminal Details
转出终端标识参数：: Channel TID
转出商户标识参数：: Channel PMR
搜索商户名称/商户标识/终端标识/SN/厂商/机型: Merchant Name/PMR/Terminal ID/Vendor/Model
重新输入: Re-enter
状态: Status
请选择状态: Please Select Status
是否重置秘钥: Whether Reset the Key?
重置密钥成功: Key Reset Successfully
终端信息: Terminal Info
# 设备登记
设备入库: Device Inbound
设备出库: Device Outbound
删除: Delete
编辑: Edit
编辑设备: Edit Device
入库方式: Inbound Method
出库方式: Outbound Method
单个入库: Single Inbound
单个出库: Single Outbound
批量入库: Batch Inbound
批量出库: Batch Outbound
设备类型: Device Type
生产厂商: Vendor
设备机型: Model
设备SN: SN
出库原因: Reason
选择文件: Select File
支持扩展名：: Supported File Extension ：
下载模板: Download Template
手动批量入库: Manual Bulk Inbound

# 用户管理
解绑: Unbind

system:
  Midrecyclingrules: PMR Recycling Rules
  Logoutfrommerchant: Merchant unregistered
  days: days
  Since: can be recycled

institution:
  institutionTitle: Organization
  institutionDetail: Details
  institutionAdd: New Institution
  institutionEdit: Edit Institution

channel:
  channelTitle: Channel
  channelDetail: Detail
  channelAdd: Edit

搜索商户订单号/流水号: Merchant Order Number/Out Transaction Number
搜索批次号: Batch Number
通道文件名称: Channel File Name

添加角色: New Role
请输入机构名称: Please Input Institution Name
全部: All
列表数据: Listed Data
开始日期: Start Date
结束日期: End Date
交易时间: Transaction Time
对账日期: Reconciliation Date
通道名称: Channel Name
对账批次: Reconciliation Batch
对账开始时间: Reconciliation Start Time
对账结束时间: Reconciliation End Time
操作: Operation
通道ID: Channel ID
交易流水号/渠道订单号: Out Transaction/Payment Order Number
交易订单号/参考号: Merchant Order Number/Reference Number
商户号: PMR
终端号: Terminal ID
交易类型: Transaction Type
交易名称: Transaction Name
交易属性: Transaction Properties
付款账户/卡号: Payment Account/Card Number
收单机构: Institution
交易币种: Transaction Currency
结算日期: Settlement Date
对账结果: Reconciliation Result
对账结果描述: Description
原交易订单号: Original Transaction Order Number
文件大小: File Size
最后更新时间: Last Update Time
操作人: Operater
机构名称: Institution Name
#差错处理词条
差错审核状态: Resolve Review Status
差错处理状态: Resolve Status
差错调账状态: Adjustment status
未处理: Not Processed
已处理: Processed
未审核: Not Reviewed
已审核: Reviewed
未对账: Not Reconciled
对账中: Reconciled
已对平: Balanced
交易要素不匹配: Transaction elements do not match
对方无流水: Channel do not have records
正交易: Positive Transaction
负交易: Negative Transaction
审核驳回: Rejected
未通过原因: Rejected Reason
未通过原因不能为空: The reason for failure cannot be blank
请填写未通过的原因: The reason for failure cannot be blank. Please fill in the reason for failure
本地交易记录: Local Transactions
渠道交易记录: Channel Transactions
条: records
审核通过: Approved
差错处理进度: Resolve Progress
执行批次: Execute Batch
待审核（已处理）: Pending Review
待调账（已审核）: Pending Adjustment
已调帐(已完成）: Adjusted
搜索订单号/交易流水号: Order Number/Out Transaction Number
请选择交易类型: Please Select Transaction Type
核销原因: Reason for write-off
核销凭证: Certificate for write-off
支持扩展名: Supported File Extension
不参与: No
参与: Yes
差错类型: Error Type
通道流水信息: Channel Transaction Info
差错处理内容: Resolve Content
差错详情: Resolution Details
处理日志: Process Log
差错原因: Error Reason
未调账: Not Adjusted
已调帐: Adjusted
确认: OK
取消: Cancel
是否确认删除处理结果？: Confirm to delete the process result?
差错处理: Resolution
新增交易系统流水: Add System Transaction
修改交易系统流水: Edit System Transaction
人工核销: Write-off
处理结果: Process Result
预览: Preview
是否参与后续对账: Whether to participate in the follow-up reconciliation
提交处理结果: Submit processing results
审核结果: Review Result
审核时间: Review Time
审核人: Reviewor
驳回原因: Reason for rejection
调账结果: Adjust Result
调账时间: Time of Adjustment
网关缺少流水: System Less Trade
网关流水异常: System Transaction Exception
上游通道多单: Channel Over Trade
上游通道少单: Channel Less Trade
其他: Others
提交结果成功: Submitted Successfully
处理中: Processing
待审核: To be Reviewed
调账中: Adjusting
已完成: Completed
上游通道单边: Channel Over Trade
交易系统单边: System Over Trade
支付要素不一致: Inconsistent Payment Elements
未完成: Not Completed
已驳回: Rejected
补录本地流水: Supplementary System Records
修改本地流水: Edit System Records
提交审核: Submit Review
搜索通道文件名称/操作人: File Name/Operator
请选择上传文件: Please Select File
文件格式不正确，请重新上传！: The file format is incorrect, please re-upload!
欢迎登录！: Welcome!
KlondPay机构平台: KlondPay Institutional Platform
用户名: User
请输入用户名: Please Input User
密码: Password
请输入密码: Please Input Password
请输入验证码: Please Input Captcha
登录: Login
#请选择交易类型: Please Select Transaction Type
#执行批次: Execute Batch

# 商户管理
商户管理: Merchant Management
商户标识: PMR
层级: Level
上级商户: Superior Merchant
上级商户名称: Superior Merchant Name
商户类型: Industry
结算周期: Billing Cycle
运营状态: Operational Status
启用状态: Status
推广员: BD
代办人: Creator
注册地址: Address
邮箱地址: Email
营业执照编号: BRC Number
营业执照有效期: Validity of BRC
结算账户: Settlement Account
SWIF编码: SWIF Code
银行编码: Bank Code
收款行名称: Bank Name
收款行城市: City of the Bank
地址: Address
扣率: MDR
转出通道: Channel
业务地区: Business Area
子商户列表: Sub-Merchant List
终端列表: Terminal List
用户列表: User List
联系邮箱: Email
产品: Product
商户标识回收状态: PMR Recycling Status
交易时区: Trading Time Zone
经手人: Transactor
配置支付产品: Payment Products
支付参数: Payment Parameters
营业执照信息: BRC Info
结算信息: Settlement Info
法人信息: Legal Person Info
上级机构名称: Parent Institution Name
搜索机构ID/机构名称: Institution ID/Institution Name
搜索商户名称/商户标识: PMR/Merchant Name

绑定角色: Bind Role
修改数据权限: Edit Data Permission
数据权限: Data Permission
姓名: Name
所属组织机构: Institution
角色: Role
创建人: Creator
邮箱: Email
手机号: Phone Number
角色名称: Name of Role
搜索用户名/姓名: User / Name
超级管理员: Administrator
管理员: Manager
机构超级管理员: Institutional Administrator
审核员: Review
添加账号: New Account
角色描述: Role Description
设置权限: Set Permissions
返回: Back
日期选择: Select Date
账号: Account
操作类型: Operation Type
搜索商户标识/商户订单号/商户名称: PMR/Merchant Order Number/Merchant Name
商户订单号: Merchant Order Number
正常: Regular
失败: Failed
差错审核: Resolve Review
通道: Branch
终端标识：: Terminal ID：
交易流水号: Out Transaction Number
交易订单号: Transaction Order Number
IP: IP
未对平: Unbalanced

请输入S/N: Please Input S/N
请输入数字或字母: Please enter numbers or letters
导入成功: Import successful
未导入: Not Imported

修改成功，请重新登录: Please log in again
新增用户: New User
编辑用户: Edit User
新增角色: New Role
编辑角色: Edit Role
# 交易流水
网关支付: System Processed
非网关支付: Uploaded

共计差错: Total Resolution
笔: ""
对账文件名称: Reconciliation File Name
数据导入状态: Data Import Status
数据上传状态: Data Upload Status
描述: Description

# 补充
机构平台: Institutional Platform
机构数量: Institutions
联系人: Contact
联系人邮箱: Contact Email
审核状态: Review Status
报备状态: Onboard Status
机构ID: Institution ID
所属层级: Level
上级机构: Superior Institution
联系人电话: Contact Number
联系电话: Contact Number
状态异常，无法编辑: The status is abnormal and cannot be edited
子机构: Sub-Institutions
商户: Merchants
终端: Terminals
当前已达到商户标识回收规则的商户如下: Merchants can be recycled as follow
回收: Recycle
一键回收: Recycle All
待报备: To be Approve
报备中: Onboarding
报备成功: Approved
报备失败: Failed
无需报备: No Onboarding Required
未启用: Not Activated
已启用: Activated
已禁用: Disabled
已注销: Unregistered
基础信息: Basic Info
永久: Permanent
支付产品: Payment Product
保存: Save
提交: Submit
外部商户号: Channel PMR
扣率(%): MDR(%)
请输入商户标识，具有唯一性: Please enter unique PMR
输入百分比 3%填写3: Input rate
提交时间: Submit Time
处理时间: Process Time
个人: Personal
企业: Enterprise
个体: Individual
待处理: To be Processed
创建时间: Creation Time
更新时间: Update Time
最近天数: Recent Days
渠道: Channel
新增终端: New Terminal
新增子商户: New Sub-Merchant
注销: Unregister
注销后，该商户将永久停用: The merchant will be permanently disabled after unregistered
是否继续操作: Continue?
当前账户金额存在负数金额: There is a negative amount in the current account amount
标签组: Tag Group
共计x笔: Taltal:{count}
已处理x笔: Processed:{count}
未处理x笔: Unprocessed:{count}
预览图片: Preview
交易要素不一致: Inconsistent Transaction Elements
新增交易流水系统: Add system transction records
核销详情: Write-off details
上传凭证失败！: Failed to upload credentials!

请填写驳回原因: Please fill in the reason for rejection
审核商户成功！即将返回: Merchant review successfully!
修改机构成功！即将返回: Modify institution successfully!
新增机构成功！即将返回: Add institution successfully!
新增商户成功！即将返回: Add merchant successfully!
保存子商户成功！即将返回: Save sub-Merchant successfully!
新增子商户成功！即将返回: Add sub-Merchant successfully!
修改商户成功！即将返回: Modify merchant successfully!
提交草稿成功！即将返回: Submitted draft successfully!
系统错误，请稍后再试: System error, please try again later
请审核完所有项目之后再提交: Please review all items before submitting
导出成功: Export Successful
禁用成功！: Disable Successfully！
启用成功！: Enable Successfully！
删除成功！: Delete Successfully！
不能同时对多个机构进行编辑！: Multiple institutions cannot be edited at the same time！
不能同时对多个商户进行操作！: Multiple merchants cannot be edited at the same time！
请选择商户: Please select a merchant
只能操作下一级机构: Can only operate on the next level institution
保存商户成功！即将返回: Save merchant successfully
请输入正确的商户标识: Please enter the correct PMR
请填写商户名称与标识: Please fill in the Merchant name and PMR
请选择营业执照有效期: Please select the validity period of the business license
存在资料未填写，请先完善: Required information is not filled in, please complete it first
请选择业务地区: Please select a business area
至少配置一个支付产品: Configure at least one payment product
请为选中的产品配置转出渠道: Please configure a channel for the selected product
不能操作其他机构商户: Cannot operate other institutional merchants
该商户无法禁用: This merchant cannot be disabled
该商户无法启用: This merchant cannot be enabled
商户禁用成功！: Disable merchant successfully！
商户启用成功！: Enable merchant successfully！
商户注销成功！: Unregister merchant successfully！
添加标签成功！: Add tag successfully！
导出成功！: Export Successful！
无可用标签，请先进行标签配置!: No tags available, please configure tags first
请不要同时注销多个商户！: Please do not unregister multiple merchants at the same time！
请选择未被注销的商户!: Please select a merchant that has not been unregistered
回收成功: Successfully Recycled
一键回收成功: Batch recycle successfully
请先选择设备: Please select a device first
请为选中的产品配置支付渠道: Please configure a payment channel for the selected product
支付渠道: Payment Channel
要素不平: Inconsistent Transaction Elements
流水单边: Sytem unilateral
SWIFT编码: SWIFT Code
立即查看: View
请选择月份: Please select a month
最近七天: Last 7 days
最近十五天: Last 15 days
最近三十天: Last 30 days
修改: Edit
报备通过: Onboarded
审核成功: Pass
待提交: To be Submitted
#----补充----
请输入账号: Please enter account
请输入正确的验证码: Please enter correct CAPTCHA
是否禁用该用户: Whether to disable this user
是否启用该用户: Whether to enable this user
模块: Module

搜索商户名称/商户标识/联系人: Merchant Name/PMR/Contact
此处为结算日期: Here is the settlement date
当周消费金额（: Amount Spent This Week（{currency}）
上一周消费金额（: Amount Spent Last Week（{currency}）
商户交易订单号: Merchant Order Number
类型: Type
厂商名称: Vendor
型号名称: Model
设备S/N: Device S/N
流水号: Out Transaction Number
请先删除再上传: Please delete before uploading

请选择上级机构名称: Please select the name of the superior organization
最长128位: Up to 128 bits
最长32位: Up to 32 bits
最长64位: Up to 64 bits
请输入正确的邮箱: Please enter vaild email
请输入手机号: Please enter phone number
请输入正确的手机号: Please enter a valid phone number
请选择商户类型: Please select Industry
请输入商户名称: Please enter merchant name
请输入商户标识(字母数字以及-): Please enter PMR((Alpha,Numeric and -))
请输入联系人: Please enter a contact
请输入注册地址: Please enter registered address
请输入营业执照编号: Please enter the business license number
请输入法人姓名: Please enter the legal person name
请输入法人ID: Please enter the legal person  ID
请输入正确的法人ID: Please enter the correct legal person ID
请输入结算账户: Please enter settlement account
请输入SWIFT编码: Please enter SWIFT code
请输入银行编码: Please enter bank code
请选择收款行名称: Please select a bank name
请选择收款行城市: Please select the bank city
请输入地址: Please enter address
请选择结算周期: Please select a billing cycle
请选择交易币种: Please select transaction currency
请选择交易时区: Please select a trading time zone
请选择机构日切时间: Please select the institution cut-off time
请选择支付产品: Please select payment product
请选择支付渠道: Please select a payment channel
请选择合作模式: Please select a cooperation mode
请选择计费周期: Please select a billing cycle
请选择计费开始时间: Please select a billing start time
请输入姓名: Please enter name
请输入邮箱: Please enter email
请选择机构组织: Please select an institution
请选择角色名称: Please select a role
请选择数据权限: Please select a data permission

商户交易订单号/商户标识/商户名称/终端标识/SN: Merchant Order Number/PMR/Merchant Name/Terminal ID
所有正向消费的总金额: The total amount of all positive consumption
所有正向消费的总笔数: The total number of all positive consumption
=消费金额/消费笔数: =Amount / Transaction
所有负向退货的总金额: The total amount of all negative returns
所有负向退货的总笔数: Total number of all negative returns
不能操作其他机构终端!: Cannot operate terminals of other institutions!
是否重置密钥: Whether to reset the key
请选择终端: Please select a terminal

支付中: Paying
拒绝: Reject
已撤销: Revoked
已冲正: Reversed

交易管理: Transaction
账务管理: Account Management
新密码不能与旧密码相同: The new password cannot be the same as the old password
排名: Ranking
商户名: Merchant Name
机构名: Institution Name
较上天: Compare the previous day
机构管理: Institution Management
列表查询: Query List
批量导入: Batch Import
查询所有商户: View All Merchants
更改状态: Change State
审核管理: Review Management
审核: Review
审核页面: Review Page
终端管理: Terminal Management
重置密钥: Reset Key
设备登记: Device registration
出库: Outbound
下载: Download
渠道管理: Channel Management
标签管理: Tag Management
删除标签组: Delete Tag Group
删除标签: Delete Tag
交易流水管理: Transaction Record
交易日志管理: Transaction Log
操作日志管理: Operational Log
系统设置: System Settings
用户管理: User Management
角色管理: Role Management
经营报告: Business Report
排行榜: Ranking
审核详情: Review Details
对账管理: Reconciliation
查看对账结果: View Reconciliation Results
导入文件: Import Files
重新对账: Reconciliation
对账文件: Reconciliation File
查询流水文件列表: View Transaction Records
查询对账结果: View Reconciliation Result
对账数据: Reconciliation Data
查询对账数据: View Reconciliation Data
查询差错进度信息: View Resolve Schedule
查询差错明细列表: View Resolve List
查询差错处理日志: View Resolve Log
查询差错详情: View Resolve Details
保存差错处理结果: Save Resolve Result
提交差错处理结果: Submit Resolve Result
服务费管理: Service Fee
查看交易明细: View Transaction Details
查询差错待审核申请单列表: View Resolve Review List
查询差错待审核进度: View Resolve Review Schedule
保存差错审核结果: Save Resolve Review Result
提交差错审核结果: Submit Resolve Review Result
请求TPS对指定差错记录调账处理: Request adjustment for specified error records
交易参考号: Transaction Reference Number
详情: Details
处理: Resolve
登录成功: Login successfully
终端标识: Terminal ID
未上传: Not Upload
上传中: Uploading
已上传: Uploaded
关闭: Close
已调账: Adjusted
审核中: Auditing
文件名称应为：: File name should be：
导入最后消息: Last Message
不通过: Not Pass
草稿: Draft
导出机构数据: Export institution data
仅能创建下一级机构，不允许跨级创建: Only the next level of organization can be created, and cross-level creation is not allowed
是否启用该机构: Whether to enable the institution
是否禁用该机构: Whether to disable the institution
是否删除该机构: Whether to delete the institution
多选: Multiple selection
是否导出: Whether to export
商户状态: Merchant status
撤销: Quash
法人姓名: Legal Person Name
法人ID: Legal Person ID
请输入SP ID: Please enter SP ID
请输入0-100: Please enter 0-100
请输入商户标识: Please enter PMR
输入百分比: Input rate
餐饮: Repast
零售: Retail
总交易笔数: Total number of transactions
总交易金额: Total transaction amount
交易金额占比: Percentage of transaction amount
未对平金额: Unbalanced amount
未对平笔数: Unbalanced number
去处理: To handle
当前账户存在未对平的账务数据，请先处理: There is unbalanced accounting data in the current account, please deal with it first
当前账户金额存在正数金额: The current account amount has a positive amount
请联系后台处理: Please contact background processing
是否禁用该商户: Whether to disable the merchant?
是否启用该商户: Whether to enable the merchant?
申请单号: Requisition Number
处理状态: Process Status
重新获取流水: Refetch Transactions
已对账: Reconciled
调账完成: Adjustmented
对账失败，请检查相关数据: Reconciliation failed, please check relevant data
付款账号/卡号: Payment Accout/Card Number
交易流水: Transaction Record
服务费币种: Service Currency
单个设备出库: Single Device Outbound
批量设备出库: Batch Device Outbound
单个设备入库: Single Device Inbound
批量设备入库: Batch Device Inbound
解绑用户: Unbind User
对账文件导入: Import File
调账: Adjust
修改用户密码: Update Password
商户打标签: Merchant Tagging
对账模块: Reconciliation
差错模块: Resolve of Error
标签模块: Tag Management
商户模块: Merchant Management
设备管理: Device Management
文件管理: File Management
当周消费笔数: Transaction This Week
消费金额占比: Amount {label} Rate
消费笔数占比: Transaction {label} Rate
消费单均价占比: Average Price {label} Rate
在库: In Stock
在用: Using
旧密码: Old Password
新密码: New Password
再次确认新密码: Confirm Password
请输入旧密码: Please enter old password
请输入新密码: Please enter a new password
搜索设备SN: Search device SN

数量: Number
暂未完成对账，无法输出数据，请耐心等待！: Reconciliation has not been completed yet, unable to output data, please wait patiently!
需对S/N校验，必填: S/N check required
该终端已禁用: This terminal is disabled
该终端已启用: This terminal is activated
新增终端成功，即将返回: Add terminal successfully!
编辑终端成功，即将返回: Edit terminal successfully!
请填写渠道终端标识参数: Please fill in the channel terminal identification parameters
部分终端已禁用: Some terminals are disabled
部分终端已启用: Some terminals are activated
请不要同时操作多个用户: Please do not operate multiple users at the same time
请选择用户: Please select a user
用户已禁用: User is disabled
用户删除成功: User deleted successfully
角色解绑成功: Role unbinding succeeded
修改用户角色成功！即将返回: Modify the user role successfully! Coming back soon
修改用户成功！即将返回: Modify user successfully! Coming back soon
操作员: Operator
自定义角色: Custom Role
确认删除吗: Whether delete the user?
确认解绑吗: Are you sure to unbind?
运营平台: Operation Platform
操作成功: Operation Success
设备批量导入结果: Device batch import results
共计导入数据: Total imported data
成功导入: Imported successfully
导入失败: Import failed

设备批量入库结果: Device batch inbound results
设备批量出库结果: Device batch outbound results
入库成功: Inbound successfully
出库成功: Outbound successfully
入库失败: Inbound failed
出库失败: Outbound failed
失败数据下载: Failed data download
上一周消费笔数: Transaction Last Week
当周消费单均价: Average Price Of This Week
上一周消费单均价: Average Price Of Last Week
第某周: The {week} week
反交易: Negative Transactions
联迪: LANDI
百富: PAX
新国都: XGD
L180机型: L180 Model
G180机型: G180 Model
八达通支付: Octopus Payment
八达通退款: Octopus Refund
八达通撤销: Octopus VOID
八达通查询: Octopus Query
更新密钥: Update Key
交换数据: Exchange Data
八达通: Octopus
中国: China
德国: Germany
中国香港: China Hong Kong
哈萨克斯坦: Kazakhstan
美国: America
北京: Beijing
成都: Chengdu
重庆: Chongqing
大连: Dalian
广州: Guangzhou
哈尔滨: Harbin
海南: Hainan
呼和浩特: Hohhot
昆明: Kunming
上海: ShangHai
三亚: Sanya
深圳: Shenzhen
浙江: Zhejiang
慕尼黑: Munich
香港: HongKong
努尔苏丹: Nur-Sultan
华盛顿: Washington
纽约: New York
旧金山: San Francisco
消费: Consumption

用户权限不足: Insufficient user rights
当前机构不允许启用: The current institution does not allow activation
当前商户状态不为草稿状态: The current merchant status is not in draft status
草稿状态无法注销: Draft status cannot be unregistered
当前已经是注销状态: Currently unregistered
在用设备不能出库: In-use device can not be outbounded
出库状态不允许修改: Outbound status is not allowed to be modified
在用状态不允许修改: In-use status does not allow modification
请填写终端支付参数: Please fill in the terminal payment parameters
终端支付参数与商户不匹配: The terminal payment parameters do not match the merchant
请填写完整终端支付参数: Please fill in the complete terminal payment parameters
该商户回填参数已经录入: The merchant's backfill parameters have been entered
当前绑定设备已经出库: The current bound device is out of stock
当前绑定设备已经在用: The currently bound device is already in use
当前机构已被禁用: The current institution is disabled
当前机构已被删除: The current institution has been removed
证件号码超长: ID Overlength
推广人超长: Promoter is too long
邮箱超长: Email is too long
联系人超长: Contact is too long
商户标识超长: PMR is too long
初审失败原因长度超长: The reason for the failure of the review is too long
设备类型长度超长: Device type length is too long
厂商code 例如：PAX: Vendor code such as PAX
厂商名: Vendor
机型code 例如：A920: Model code such as A920
机型名: Model
设备类型（1-pos终端）: Devcie Type（1-POS）
设备序列号sn: Device S/N
出库原因（出库须填）: Outbound Reason
导入失败原因: Import failure reason
请填写完整的设备信息: Please fill in complete device information
请填写正确的设备类型: Please fill in the correct device type
出库设备在用: Outbound devcie in use
出库设备已出库: Outbound device is already outbounded
其他原因: Other reasons
设备sn重复: Depulicate S/N
厂商和机型不匹配: Vendor and Model does not match
设备类型有误: Wrong device type
#---清结算后端词条---
请求成功: Request Succeeded
系统错误，请重试: System error, please try again
登录超时,请重新登录: Login timed out, please login again
请重新登录！: Please login again
请重新登录!: Please login again
未授权，拒绝访问: Unauthorized, Access Denied
业务处理异常: Business processing exception
非法参数: llegalI parameter
非法的请求方式: llegal reqIuest
文件为空，请选择件: The file is empty, please select a file
上传文件成功: Uploaded file successfully
上传失败，请选择文件: Upload failed, please select a file
未知错误: Unknown mistake
下载文件失败: Download file failed
上传文件失败: Failed to upload file
机构ID为空: Institution ID is empty
商户号为空: PMR is empty
未找到对账批次信息: Reconciliation batch information not found
未找到差错进度信息: Error progress information not found
未找到差错记录: Error record not found
不允许进行差错处理: Error resolve is not allowed
未知的差错处理方法: Unknown error resolve method
存在相同订单号的网关流水: There is gateway transaction record with the same order number
新增网关流水失败: Add  gateway transaction record failed
修改网关流水失败: Modify gateway transaction record failed
保存差错处理结果失败: Save error resolve result failed
请先处理完所有的差错: Please complete all the error resolve first
无差错记录需要审核: There is not error resolve required to be reviewed
共提交%d条差错处理结果, 其中: Total submit{count}error resolve result, amount these
差错待审核单号不能为空: Error resolve review number cannot be empty
未找到指定差错待审核单: Can not find specified resolve review record
该记录不允许进行审核操作: Can not review this record
保存差错审核结果失败: Failed to save error resolve review results
无差错审核记录可以提交: There is not error resolve review record can be submitted
请先处理完所有差错记录: Please complete all the error resolve first
请先审核完所有差错处理结果: Please complete reviewing all error resolve first.
所有差错记录都已调账完成: All error records have been adjusted
有d条差错记录调账失败！: There are {count} records adjust have been failed！
通知TPS程序生成本地流水文件失败！: Generate transaction record file failed！
TPS重新生成流水文件成功: Generate transaction record success!
无效帐单ID: Invalid Billing ID
全选: Select All
请先选择一种处理方式: Please Select a processing method first
重新对账完成: Re-reconciliation Completed
系统初始化失败: System Initialization Failed
系统预处理失败: System Preprocessing Failed
连接STTL后台服务失败，请检查STTL后台服务: Failed to Connect to STTL Service
系统繁忙，请稍后再操作: System is busy, please operate later
汇总失败，请检查相关数据: Failed to summarize, please check the relevant data
请求TPS重新上传流水文件: Request the TPS to upload the stream file again
笔数: Number
金额: Amount
当月排名: Ranking Of This Month
较上一月排名变化: Rank Changing From The Last Month
当周排名: Ranking Of This Week
较上一周排名变化: Rank Changing From The Last Week
较去年同月排名变化: Rank Changing From Compared To Same Month Last Year
绑定角色成功！: Role binding succeeded!
删除角色成功！: Role deleted successfully!
修改密码成功: Password modification succeeded

组织架构管理: Institution Structure
查询商户列表: Merchant List
修改机构回收MID天数: Modify days for institution to recycle PMR
批量插入机构: Batch Insert Institution
修改机构: Modify  Institution
新增机构: New  Institution
禁用机构: Disable  Institution
删除机构: Delete  Institution
启用机构: Enable  Institution
修改商户: Modify Merchant
审核商户: Review Merchant
启用、禁用、注销商户: "Enable, Disable, Unregister Merchants"
设备批量入库: Device Batch Inbound
设备批量出库: Device Batch Outbound
设备信息修改: Modify Device Info
设备删除: Delete Device
启用、禁用、注销、删除终端: "Enable, Disable, Unregister, Delete Terminals"
修改终端: Modify Terminal
创建用户: New User
修改用户: Modify User
启用用户: Enable User
冻结用户: Disable User
修改密码: Modify Password
删除用户: Delete User
创建角色: New Role
删除角色: Delete Role
修改角色: Modify Role
机构业务开展国家不包含该国家: Institution conducts business does not include this country
上级机构不存在: Superior institution does not exist
不能跨级创建机构: Cannot create institutions across superior
六级机构不能添加子机构: This is already the buttom level institution
机构不存在: Institution does not exist
当前用户所属机构状态异常: Abnormal state of the institution to which the current user belongs
当前机构不是0级机构: Current institution is not the top level institution
该机构当前不处于停用状态: The institution is not currently disabled
在停用180天后方可删除，当前还剩: "Can be deleted after 180 days of deactivation, currently remaining"
当前机构不处于禁用状态: The institution is not currently disabled
所选机构不存在: Institution does not exist
子商户标识不能和父商户重复: PMR already used
商户标识已经存在: PMR already used
非0级商户商户不能添加子商户: Sub-Merchant is the buttom level already
当前商户状态异常不能添加子商户: The current merchant status is abnormal and sub-Merchants cannot be added
所选机构缺少接入通道配置，请联系管理员: "The selected institution lacks channel configuration, please contact the administrator"
所选商户不存在: The selected merchant does not exist
当前用户信息缺失: The current user information is missing
当前机构不能查看该商户: The current institution cannot view this merchant
当前待审核状态不能修改: The merchant which is under reviewing can not be modified
当前报备中状态不能修改: The merchant which is onboarding can not be modified
所选机构信息不完整，请联系管理员: "The selected institution information is incomplete, please contact management"
当前登录信息已过期: Current login information has expired
当前商户信息操作权限受限: Do not have permission to operation on the current merchant
商户没有待处理的记录: Merchant has no pending records
商户待审核记录已处理: Merchant pending review records have been processed
注销状态不能进行其他操作: No other operations can be performed on this merchant
当前商户状态不允许注销: The current merchant status does not allow to be unregistered
该sn已经存在: The S/N already exists
出库设备信息错误: Incorrect outbound device info
该设备已经被使用，暂不能删除: The device has been used and cannot be deleted now
设备不存在: The device does not exist
下载模板失败，请联系管理员: "Failed to download template, please contact administrator"
修改设备不存在: The modified device does not exist
机构信息未录入: Institution info does not exist
修改信息失败: Failed to modify
文件类型错误: Incorrect file type
数据不能为空: Data can not be empty
解析excel文件出错: Error parsing the file
长度超长: Over longth
厂商与机型不匹配: Vendor and Model does not match
终端信息不存在: Termnial does not exist
终端与机构关系错误: Incorrect terminal and institution relation
商户与机构关系错误: Incorrect merchant and institution relation
该终端标识已经存在: Terminal ID is exist
该设备已被绑定: The device is already in use
当前设备不属于该机构: The current device does not belong to this institution
当前设备已经出库: The current device is not in storage
请录入设备信息: Please enter device info
终端修改状态错误: Incorrect terminal modification status
当前机构不能对该终端进行修改: The current institution can not modify this terminal
终端状态异常，暂不能重置密钥: "Incorrect terminal status, can not reset key now"
当前机构不能对该终端进行操作: The current institution can not operate on this terminal
没有操作权限: No operating permission
标签名已被使用!: Tag name has been used
商户业务开展地区错误: Merchant business area incorrect
0级机构不存在: Institution does not exist
商户当前状态不能参与审核: Merchant can not be review
当前商户状态不允许启用: Merchant can not be enabled
商户只允许禁用状态下才能注销: Merchants are only allowed to unregister in a disabled state
商户信息不存在: Merchant info does not exist
权限不足: Do not have permission
该商户账务异常，无法注销: Merchant account abnormal can not be unregistered
请先删除该商户下的终端资源: Please delete the terminal under this merchant
用户名或密码错误: Incorrect user name or password
用户已锁定: The user has been locked
用户名不存在: User name does not exist
标签组名已被使用!: The name of the tag group has been used
标签组不存在: Tag group does not exist
设备机构信息未录入: Institution info of the device is required
当前设备信息与用户不匹配: The current device information does not match the user
出库设备不存在: The outbound device does not exist
不能对当前机构进行删除: Can not delete the current institution
该机构不能删除: The current institution does not exist
不能对当前机构进行禁用: Can not disable the current institution
状态为启用的机构才可以禁用: Only can disable the institution which status is enabled
没有对该机构的操作权限: Do not have permission to operate on this institution
该商户已经是禁用状态了: The merchant is at status of disabled already
该商户已经是注销状态了: The merchant is at status of unregistered already
该商户不能删除: This merchant can not be deleted
邮件发送失败: Failed to send the email.
系统错误: System Error
渠道不存在: Payment channel does not exist
渠道已被禁用: Payment channel is disabled
请先解绑同用户的关联关系!: Please unbind with the user first
机构未选择: Does not select the institution
用户名已存在: User name is exist
用户邮箱已存在: Contact email is exist
用户已被禁用: User is disabled
错误的用户名或者密码: Incorrect user name or password
旧密码错误: The original password is not correct
密码错误: The password is not correct
密码错误!: The password is not correct!
是否确认删除角色？: Whether delete the role?
手动入库: Manual Inbound
手动出库: Manual outbound
报损出库: Outbound because of damage
其他出库: Outbound for other reason
批量处理失败明细: Batch process failure details
设备入库出库模板: Device inbound and outbound template
请再次输入新密码: Please enter the new password again
两次输入不一致: The two entries are inconsistent
登录模块: Login Management
长期: Permanent
支付宝: Alipay
微信: Wechat
银联: UnionPay
渠道终端标识参数: Channel terminal id parameters
渠道商户标识参数: Channel PMR parameters
请输入名称: Please enter a name
请输入描述: Please enter a description
添加角色成功！即将返回: Added role successfully! coming back soon
修改角色成功！即将返回: Modify the role successfully! coming back soon
支付对账: Payment Reconciliation
平台: Platform
添加用户成功！即将返回: Add user successfully! coming back soon
所属机构组织: Affiliated Organization
平台超级管理员-初始: Platform Administrator-initial
平台管理员-初始: Platform Manager-Initial
平台操作员-初始: Platform Operator - Initial
机构超级管理员(非0级)-初始: Sub-Institution Administrator -Initial
机构管理员(非0级)-初始: Sub-Institution Manager-Initial
机构操作员(非0级)-初始: Sub-Institution Operator-Initial
机构审核员(非0级)-初始: Sub-Institution Auditor-Initial
机构超级管理员(0级)-初始: Institution Administrator-Initial
机构管理员(0级)-初始: Institution Manager-Initial
机构操作员(0级)-初始: Institution Operator-Initial
机构审核员(0级)-初始: Institutional Auditor-Initial
备注: Remark
交易要素不平: Transaction elements do not match
子商户启用失败，因所属商户未启用: Not able to enable sub-Merchant untill the merchant is enabled
当前机构不能操作该商户: The current organization cannot operate the merchant
请不要同时操作多个设备！: Please do not operate multiple devices at the same time！
商户业务逻辑id不能为空: Please select a merchant
请选择非注销、草稿状态的商户: Please select a merchant that is not deregistered, drafted
当前用户没有权限对该机构进行修改: The current user does not have permission to make modifications to the institution
请选择未出库设备: Please select an unshipped device
请选择在库设备: Please select device in stock
打标签: Tag

确认解冻吗: Whether to Enable？
确认冻结吗: Whether to Disable？
冻结: Disable
解冻: Enable

确认重置密码吗: Are you sure to reset your password？
已复制到剪切板: Copied to clipboard
6-20位: 6-20 characters
商户超级管理员: Merchant super admin
门店超级管理员: Store super admin
重置密码: Reset Password
创建中: Creating
创建失败: Creation failed
手机: Phone
商户添加账号: Merchants Add Accounts
商户不存在: Merchant Not Exist
门店不存在: Store Not Exist
对账未完成: The reconciliation is not complete
保存密码: Save the password
请妥善保管您的密码: Please keep your password safe
请输入正确的电话号码: Please enter the correct phone number
手机号码已存在: The Phone already exists
手机号已存在: The Phone already exists
邮箱已存在: The Email already exists
#---补充分割线----
经营地址: Store Address
图片大小不能超过2M!: picture size can not exceed 2MB!
请输入: Please input
门店名称: Store Name
APP下载链接: APP Download Link
业务网站: Website
负责人名称: Principal
负责人ID: Principal ID
公众号: Offical Account
小程序: Mini Program
商户类目: Merchant Type
门头照: Door
建议尺寸: Recommended size
限制大小: ""
店内照: Inside Store
收银台照: Casher
图片格式错误!: Image format error!
微信线下: Wechat Offline
微信线上: Wechat Online
支付宝线上: Alipay Online
支付宝线下: Alipay Offline
公司电话: Office Phone
请完善照片: Please upload Pictures
行业类目: Business Category
报备详情: Onboard Details
VISA分期: VISA Installment
所属地区: Region
其他参数: Other Parameters
请选择: Please select
当前设备已存在: Device already exists
是: Yes
否: No
商 户: Merchant
终 端: Terminal

# visa
Export file generating, please wait: Export file generating, please wait
Estimated completion time: Estimated completion time
You can make the exporting: You can make the exporting
Process at background: Process at background
Cancel the exporting: Cancel the exporting
or: or
After the file is generated, it will download automatically: After the file is generated, it will download automatically
是否导出机构数据: Whether to export institution data?
是否导出商户数据: Whether to export merchant data?
是否导出终端数据: Whether to export terminal data?
导入: Import

请稍等，正在导出: Exporting，please wait
商户导出: Merchants Export
终端导出: Terminals Export
商户导入结果: Merchants imported results
终端导入结果: Terminals imported results
是否停用该商户: Whether to disable the merchant?
停用: Disable

请选择所属地区: Please select your region
文件名称: File Name
下载失败记录数: Failed download records
是否下载失败记录数: Whether to download failure records
是否下载文件: Whether to download files
文件生成完毕: File generated
正在计算: Calculating
只支持扩展名为xls、xlsx的文件: Only support xls and xlsx files
一次只能上传一个文件: Only one file can be uploaded at a time
启用商户: Enable
商户停用成功！: Disable merchant successfully！

导入时间: Import Time
导入状态: Import Status
导入类型: Import Type
总记录数: Total
成功记录数: Success
失败记录数: Failure
下载失败记录: Download Failure Record
批量导入终端: Import Terminals
批量导入商户: Import Merchants
是否下载失败记录: Whether to download failure records
导出时间: Export Time
文件状态: File Status
成功: Succeed
生成成功: Generated Successfully
生成失败: Generated Failed

至少选择一项: Select at least one

确认分期计划: Confirm Installment plan
获取分期计划: Get Installment plan
分期状态: Installment Status
分期计划ID: Installment Plan ID
确认分期ID: Confirm Installment ID
分期期数: Number of Installments
还款周期: Repayment Period
分期总额: Total Installments
首期本金: Down Payment
首期手续费: Initial Fee
末期本金: Final Principal
末期手续费: Final Fee
分期总手续费: Total Fee

支付宝线下进件: Alipay Instore Onboard
支付宝线上进件: Alipay Online Onboard
VISA进件: VISA Onboard
微信进件: Wechat Onboard
终端批量导入: Import Terminals
终端批量导出: Export Terminals
商户批量导入: Import Merchants
商户批量导出: Export Merchants
Outbound because of damage: Outbound because of damage

# 产品改名
Octopus: Octopus
Visa Installment: Visa Installment
WeChat Offline: WeChat Offline
WeChat Online: WeChat Online
Alipay Offline: Alipay Offline
Alipay Online: Alipay Online
# 后端采集
Merchant不能为空: Merchant can not be empty
TID不能为空: TID can not be empty
S/N不能为空: S/N can not be empty
Vendor不能为空: Vendor can not be empty
Model不能为空: Model can not be empty
Merchant格式错误: Merchant格式错误
终端重复:TID: Duplic Terminal:TID
终端重复：S/N Vendor Model: Duplic Terminal：S/N Vendor Model
必填参数不能为空: Mandatory parameter can not be empty
Merchant不存在,或者商户当前状态不支持新增终端,或者不支持其子机构下的商户导入: Merchant does not exist, or the current state of the merchant does not support new terminals, or does not support the import of merchants for sub-organizations
不满足其它约束问题: Does not satisfy other constraints
Merchant Name不能为空: Merchant Name can not be empty
Merchant ID不能为空: PMR can not be empty
Contact不能为空: Contact can not be empty
Contact Email不能为空: Contact Email can not be empty
Address不能为空: Address can not be empty
Country不能为空: Country can not be empty
Time Zone不能为空: Time Zone can not be empty
Phone不能为空: Phone can not be empty
Product不能为空: Product can not be empty
Channel不能为空: Channel can not be empty
Country不存在: Country can not be empty
Time Zone不存在: Time Zone can not be empty
Product不存在: Product can not be empty
Channel不存在: Channel can not be empty
同一0级机构下的机构及其子机构间不能出现重复的商户号Merchant ID: PMR can not be repeated
提示: Notice
请输入6到13位正确的手机号码: Please enter 6 to 13 correct mobile phone numbers
邮箱格式不正确: E-mail format is incorrect
格式不正确: Incorrect Format
商户模板下载: Download Template
批量导出商户: Export Merchants
商户导出失败记录: Export Failed Records
批量导出终端: Export Terminals
终端导出失败记录: Export Failed Records
终端模板下载: Download Template
超时: Time out
Sub Merchant ID: Sub PMR
正在努力加载中...: Struggling to load...

异常: Exception
重置成功: Reset successfully！
是否重置该用户密码: Whether to reset the user's password
复制: Copy
7位以上数字和大小写字母: More than 7 digits with uppercase with lowercase letters
密码输入错误次数达到5次，用户已被锁定，请联系管理员解锁: The password has been entered incorrectly 5 times, and the user has been locked. Please contact the administrator to enable it
用户不存在或者状态异常: The user does not exist or has an abnormal status
密码不能和前四次中的任何一次相同: The password cannot be the same as any of the first four times
密码包含了不支持的字符: The password contains unsupported characters
密码长度不能小于7: The password length cannot be less than 7
密码长度不能大于32: The password length cannot be greater than 32
重置用户密码: Reset user password
密码已超过90天未修改: The password has not been changed for more than 90 days
账号首次登录，需要修改密码: Account login for the first time requires password modification
至少七位，必须含字母和数字: At least seven digits, must contain letters and numbers
签名状态: Signature Status
不能重置本用户的密码: This user's password cannot be reset
当前渠道密钥保存方式未知: The current channel key storage method is unknown
修改渠道成功！即将返回: Modify channel successfully!
渠道名称: Channel Name
渠道ID: Channel ID
搜索渠道名称/渠道ID: Channel Name/Channel ID
未配置: Not configured
配置状态: Configuration status
配置完成: Configuration Complete
无法删除当前用户: Unable to delete current user
重新配置: Reconfigure
最长4096位: Up to 4096 bits
商户标识-标题: PMR

商户的唯一标识: Unique identification of the merchant

原商户订单号/商户标识/商户名称/终端标识/SN: Original Merchant Order Number/PMR/Merchant Name/Terminal ID/SN
原商户订单号/商户标识/商户名称/终端标识: Original Merchant Order Number/PMR/Merchant Name/Terminal ID

原商户交易订单号: Original Merchant Order Number
渠道处理中: Channel Processing
系统处理中: System Processing
重发: Resend

请输入正数: Please enter a positive number
请输入正确格式: Please enter the correct format
最多两位小数: Two Decimal Places


审核失败: Review Failed

是否发起查询: Whether to initiate a query?

审核通过-按钮: Approve
审核驳回-按钮: Reject
审核驳回-标题: Review Reject
驳回: Reject
vPlanRef: Plan Ref
externalTransactionRef: External Transaction Ref
vPlanID: Plan ID
vPlanAcceptanceId: PlanAcceptance ID
生成中: Generating

渠道配置: Channel
渠道列表: Query List
修改渠道参数: Edit

获取退款订单列表: Query List
响应超时，请稍后再试: The response timed out, please try again later
获取时区失败: Failed to get time zone
获取邮箱失败: Failed to get email

无法获取: Unavailable
发送成功: Sent successfully
重置双因子认证: Reset two-factor authentication
verifyTip1: Please use Google Authenticator to scan the QR code below to add the KlondPay system for two-factor verification
verifyTip2: Check out the dynamic verification code in Google Authenticator

verifyTip3: The validity period of the dynamic verification code is 30S
verifyTip4: Please use the Google Authenticator account attached to KlondPay
verifyTip5: You can use the email address to reset the two-factor authentication of the KlondPay system. After the successful reset, you need to use Google Authenticator to scan the QR code again to add the KlondPay system for two-factor authentication.

验证码已经失效: The verification code had expired
code不正确: Dynamic Verification Code verification failed
请勿在60S内重复获取验证码: Please do not obtain the verification code repeatedly within 60 seconds
code验证不通过: Code verification failed
当前账号: Current Account
动态验证码: Dynamic Verification Code
重新发送: Resend
发送验证码: Send Verification Code
请重新登录完成双因子验证配置: Log in again to complete the two-factor authentication configuration
动态验证码验证不通过: Dynamic Verification Code verification failed
获取绑定认证二维码链接: Get Google Authenticator QR Code Link
检查认证因子CODE是否正确: Check Google Authenticator Code
重置谷歌密钥: Reset Google Authenticator Key
检查邮箱验证码是否正确: Check Email Code
outtransactionno: Out Transaction Number
商户交易订单号/商户标识/商户名称/终端标识/SN/流水号/分期MID: Merchant Order Number/PMR/Merchant Name/Terminal ID/Out Transaction Number/External Transaction Ref
商户交易订单号/商户标识/商户名称/终端标识/SN/流水号: Merchant Order Number/PMR/Merchant Name/Terminal ID/Out Transaction Number


# 整理new




汇率转换: Exchange Rate Conversion
默认0: Default 0
转换币种: Conversion Currency
转换金额: Conversion Amount
汇率: Exchange Rate
设置转换汇率: Set conversion exchange rate
无需配置: No configuration required
最长40位: Up to 40 bits
请保存Key: Please save the Key
Key is empty: Key is empty

商户分组: Merchant Grouping
请选择商户分组: Please select merchant grouping
默认分组: Default grouping
最少6位: Minimum 6 bits
是否导出流水数据: Whether to export transactions data?
生成中，请在文件管理查看文件: Building, please view the file in File management

清除全部: Clear All
清除: Clear
是否清除全部记录: Whether to clear all records?

交易方式: Transaction Mode
交易单号: Order Number
终端号(TID): Terminal ID
商户号(PMR): PMR
结算金额: Settlement Amount
商户费率: MDR
交易手续费: Transaction Fee
结算净额: Net Settlement
结算管理: Settlement
结算明细: Settlement Detail
结算汇总: Settlement Summary
是否导出数据: Whether to export data?

查询明细列表: Query Detail List
查询明细导出: Export
查询明细币种列表: Query Currency List
查询结算汇总列表: Query Summary List
查询结算汇总币种列表: Query Currency List
查询结算汇总导出: Export

密码不能与前 14 次相同: The password cannot be the same as the previous 14 times
共: Total
条分页: ""
支付金额: Payment Amount
资金管理: Fund
资金结算报表: Fund Settlement Report
付款批次: Batch Number
已结算金额: Amount
结算币种: Currency
支付净额: Net Amount
手续费金额: Fee
资金结算报表数据，以北京东8区时间为基准统计: Fund Settlement Report Data, Statistic Based on Beijing Time (UTC+8)
交易开始日期: Trade Start Date
交易结束日期: Trade End Date
错误的日期: Wrong Date
是否导出报表: Whether to export reports
导出流水: Export
删除导出历史: Delete Export History

文件配置信息已存在: The configuration information already exists
文件配置不存在: File configuration does not exist
修改配置信息失败: Failed to modify configuration information

文件自动化: File Automation
文件配置: File Configuration
过程追踪: Process Tracking
文件类型: File Type
文件格式: File Format
（用于配置客户的FTP通讯地址）: For Configuring Customer FTP Server Address
端口号: Port Number
访问配置: Access Configuration
访问路径: Access Path
密码类型: Password Type
登录密码(字母、数字、特殊字符组合）: Login Password Alphanumeric Special Characters
自定义，不可重名: Custom No Duplicate Names
不包含汉字、空格以及换行符: No Chinese Characters Spaces Or Line Breaks
不支持的密码格式: Unsupported Password Format
通信配置: Communication Configuration
通知开关: Notification Switch
通知方式: Notification Method
邮件通知: Email Notification
通知时间: Notification Time
（系统将在文件生成并传输成功后，自动通知至目标邮箱）: （The system will automatically notify the target mailbox after the file is successfully generated）
收件人: Recipient
抄送人: CC Recipient
已选择: Selected
内容配置: Content Configuration
数据过滤: Data Filtering
包含的商户组: Included Merchant Groups
生成频率：每日: Generation Frequency： Daily
生成状态: Generation Status
FTP传输状态: FTP Transfer Status
邮件发送状态: Email Sending Status
重新生成: Regenerate
重新传输: Retransmit
是否重新生成: Whether to Regenerate
是否重新传输: Whether to Retransmit
是否重新发送: Whether to Resend
未生成: Not Generated
传输中: Transferring
未发送: Not Sent
发送中: Sending
名称重复: Name Duplicate
操作成功！即将返回: Operation Success Returning Soon
请选择收件人: Please Select Recipient
仅数字: Only Numbers
仅数字和.: Only Numbers And Period
FTP配置: FTP Set
通知配置: Notify Set
是否删除: Whether to delete

重新生成报表: Regenerate Report
重新发送FTP: Resend FTP
重新发送邮件: Resend Email
请先配置邮件信息: Please configure email first
请先配置FTP信息: Please configure FTP first
格式错误: Formatting Error
配置不存在，不允许重新生成: Configuration does not exist, cannot be regenerated
创建日期: Creation Date
文件追踪: File Tracking

开始时间: Start Time
结束时间: End Time

报表模块: Report Module
关闭FTP配置: Close FTP configuration
重新上传FTP: Reupload FTP
导出结算明细: Export settlement details
导出结算汇总: Export settlement summary
导出资金结算: Export fund settlement
实时: Real Time
流水模块: Transaction Module

# 编辑文件参数: Edit File Parameters
# 配置FTP参数: Configure FTP Parameters
# 配置通知参数: Configure Notification Parameters
# 修改FTP参数: Modify FTP Parameters
# 修改通知参数: Modify Notification Parameters
# 停用FTP参数: Disable FTP Parameters
# 停用通知参数: Disable Notification Parameters
导入与导出: Import/Export
请先启用邮件信息: Please enable mail information first
请先启用FTP配置: Please enable the FTP configuration first
报表还未生成成功: The report has not been successfully generated yet
当前报表已在生成中，请稍候: The current report is already being generated, please wait
sftp上传失败: SFTP upload failed
威富通: Wellforce
本地流水对账: Local journal reconciliation
批次号: Batch Number
交易流水详情: Transaction Details
交易日志详情: Transaction Log Details

原交易详情: Original Transaction Details
数据生成状态: Data generation state
数据未生成: Data not generated
数据已生成: Data generated
数据已生成表示当天所有渠道的数据已进入结算环节，不可再修改；若数据未生成，则表示尚有未进入结算环节的渠道数据，此渠道数据仍可修改。: Data generated indicates that the data of all channels on the day has entered the settlement process and cannot be modified; <br /> If the data is not generated, it means that there is still channel data that has not entered the settlement process, and this channel data can still be modified.
数据已生成表示当天所有渠道的数据已进入结算环节，不可再修改；: Data generated indicates that the data of all channels on the day has entered the settlement process and cannot be modified;
若数据未生成，则表示尚有未进入结算环节的渠道数据，此渠道数据仍可修改。: If the data is not generated, it means that there is still channel data that has not entered the settlement process, and this channel data can still be modified.
款项类型: Payment Type
补录款: Supplementary Entry
冲销款: Write-off
冲销: Charge Against
商户注册名称: Merchant registered name
请输入商户注册名称: Please enter the registered merchant name
支付时间: Payment Time
请输入营业执照名称: Please enter business license name
营业执照名称: Business License Name
按钮关闭: Close
登记时间: Registration Time
结算异常: Settlement Abnormality
本页面展示对账日期内产生结算异常的交易流水信息，需进行结算异常处理；处理完成后，相关款项将反映在商户的指定结算明细表中。: This page displays the transaction stream information with settlement abnormalities that occurred within the reconciliation period; after processing the settlement abnormalities, the relevant amounts will be reflected in the designated settlement detail statement of the merchant.
冲销调整: Write-off Adjustment
新增一条与原流水相同的负向款项，用于抵消本地多出款项: Add a negative payment equal to the original flow to offset the local excess payment
手续费: Fee
处理类型: Processing Type
冲销款项明细: Write-off Amount Details
结算异常列表: Settlement Abnormal List
结算异常详情: Settlement Abnormal Detail
结算异常处置: Settlement Abnormal Handle
缺少对应流水: Lack of corresponding flow data
交易金额不等: The Transaction Amount Varies
Shift4交易: Shift4 Transaction


配置支付接入参数: Configure payment access parameters
配置接入参数: Configure access parameters
支付方式: Payment methods
线上支付: Online
线下支付: Offline
接入密钥: Access key
自动生成: Auto Generate
线上支付接入参数: Online payment access parameters
若选择线下支付，系统将在终端首次连接时自动更新密钥，后续如需重置密钥，请: If offline payment is selected, the system will automatically update the key when the terminal is connected for the first time. If you need to reset the key later, please
前往“终端管理”: go to "Terminal Management"
操作。: operation.
10-64位: 10 - 64 bits

组织信息: Organizational Information
银行卡在线支付: Online Payment Via Bank Card
扫码支付: Scan Code Payment
卡号: Card Number
卡品牌: Card Brand
卡类型: Card Type
发卡机构: Card Institution
普通交易日志: General transaction log
预授权交易日志: Pre-authorized transaction log
业务订单号: Business order number
授权单号: Authorization number
授权转支付: Authorization transfer payment
授权状态: Authorization status
下单时间: Order placement time
授权时间: Authorization time
授权类型: Authorization type
开通产品: Launched Products

机构所在地时区: Institution Time Zone
机构交易日切时间: Institution Cut-off Time
业务开展地区/国家: Business Area/Country
Server接入配置: Server Access Configuration
注意:用于通过Server-to-Server模式接入网关场景中的密钥配置，实现身份认证和安全链接。: Attention:Key configuration for secure Server-to-Server gateway access.


服务商: Service Provider
商户设置密钥: Set Merchant Key
密钥不对: Key not right

分期MID: External Transaction Ref


# 菜单
退货记录: Refund Records
退货审核: Refund Review
# 退货
输入金额不能大于可退货金额: Refund amount cannot be greater than refundable amount
请输入退货金额: Please enter the refund amount
是否重新发起退货: Whether to re-initiate refund
重新发起退货: Re-initiate refund
退货: Refund
退货失败: Refund failed
退货成功: Refund successful
退货金额: Refund Amount
可退货金额: Refundable Amount
退货申请提交成功: Refund request submitted successfully
退货申请提交失败，请重试: Refund request submission failed, please try again
当前已有退货申请在处理中: A return application is currently being processed
退货记录详情: Refund Records Details




# 菜单
退款记录 (VISA): Plan Cancellation
退款审核 (VISA): Cancel Review
退款审核: Refund Review
# 退款
部分退款: Partial refund
已退款: Refunded
退款: Refund
分期退款: Plan Refundable
退款金额: Refund Amount
退款状态: Status
退款失败: Refund Failed
退款成功: Refund Succeed
退款仅支持180天之内的交易: Refund only support transactions within 180 days
退款金额不能超过原交易金额: The refund amount cannot exceed the original transaction amount
当前退款结果未知，请先查询结果: The current refund result is unknown, please check the result first
当前退款状态已变化，请稍候再试: The current refund status has changed. Please try again later
退款模块: Refund
提交退款: Submit Refund
重新提交退款: Resend refund
退款管理: Refund Management
退款列表: Query List
查询退款结果: Query Refund Result
重新发起退款申请: Resend Refund Request
退款金额-结算: Refund Amount
退款详情: Refund Details
退款记录详情: Refund Records Details
退款审核管理: Refund Review Management


# VISA
# 退款
VISA退款详情: Cancel Details
VISA部分退款: Partial cancel
VISA已退款: Cancelled
VISA退款成功: Cancel Succeed
VISA退款失败: Cancel Failed
VISA退款记录详情: Plan Cancellation Details


# 退货
VISA退货: Cancel
VISA重新发起退货: Re-initiate cancel
VISA可退货金额: Cancellable Amount
VISA退货金额: Cancel Amount
VISA是否重新发起退货: Whether to re-initiate cancel
VISA退货申请提交成功: Cancel request submitted successfully
VISA退货申请提交失败，请重试: Cancel request submission failed, please try again
VISA退货成功: Cancel successful
VISA退货失败: Cancel failed
VISA请输入退货金额: Please enter the cancel amount
VISA输入金额不能大于可退货金额: Cancel amount cannot be greater than cancellable amount


# new
交易退款: Transaction Refund
交易审核: Transaction Review
