<template>
  <el-config-provider :locale="currentLocale">
    <router-view />
  </el-config-provider>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ElConfigProvider } from "element-plus";
import { storageSession } from "/@/utils/storage";
import { useUserStoreHook } from "/@/store/modules/user";
import { computed } from "vue";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
import en from "element-plus/lib/locale/lang/en";
// import enVisa from "element-plus/lib/locale/lang/visa";

// let visaIsb = storageSession.getItem("visaIsb");
// console.log("visaIsb====", visaIsb);

// storageSession.setItem("HasVisa", true);
let enReal = en;
// let showVISA = storageSession.getItem('HasVisa')
// if(showVISA){
//   // enReal = visa
// }
// console.log('enReal',enReal);

export default defineComponent({
  name: "app",
  components: {
    [ElConfigProvider.name]: ElConfigProvider
  },
  computed: {
    currentLocale() {
      return this.$storage.locale?.locale === "zh" ? zhCn : en;
    }
  }
});
</script>
